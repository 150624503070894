import { FormRoutes } from '@core/enums/routes.enum';
import { BehaviorSubject } from 'rxjs';

export enum FormState {
  isSubmitting = 'is-submitting',
  isLoading = 'is-loading',
  isIdle = 'is-idle',
  isEditing = 'is-editing',
}

export interface FormConfig {
  mode?: FormRoutes;
  state?: FormState;
  submitIcon$?: BehaviorSubject<string>;
}

export class FormHelper {
  public static isEdit(formConfig: FormConfig): boolean {
    return formConfig.mode === FormRoutes.edit;
  }

  public static isAdd(formConfig: FormConfig): boolean {
    return formConfig.mode === FormRoutes.add;
  }

  public static isCopy(formConfig: FormConfig): boolean {
    return formConfig.mode === FormRoutes.copy;
  }

  public static isSubmitting(formConfig: FormConfig): boolean {
    return formConfig.state === FormState.isSubmitting;
  }
}
